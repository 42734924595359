
import {WOW} from '../../../node_modules/wowjs/dist/wow';

// new WOW().init();
//内容加载动画插件
// $(document).ready(function () {
//   new WOW({
//     live: false,
//     offset: 0
//   }).init();
// })

//页面滚动监听
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  if (scroH > 50) { //距离顶部大于140px时
    $('header').addClass('scroll-header')
  } else {
    $('header').removeClass('scroll-header')
  }
  if (contentH - (scroH + viewH) <= 100) { //距离底部高度小于100px
    $('.footer .animate-circle').addClass('active')
  }else(
    $('.footer .animate-circle').removeClass('active')
  )
  if (contentH = (scroH + viewH)) { //滚动条滑到底部啦

  }

});