import './index.scss'

$('[data-open="cmenu"]').on('click', function () {
    if ($('.header .hamburger').hasClass('active')) {
        $('.header .hamburger').removeClass('active')
        $('header .menu-nav').removeClass('active')
        $('.menu-mask').fadeOut(200)
    } else {
        $('.menu-mask').fadeIn(200)
        $('.header .hamburger').addClass('active')
        $('header .menu-nav').addClass('active')
    }
})

// $(".header .pc-nav ul li").mouseenter(function () {
//     if (!$(this).hasClass('active')) {
//         $(this).addClass('hover');
//         $(this).siblings('.active').addClass('not-active');
//     }
// }).mouseleave(function () {
//     if (!$(this).hasClass('active')) {
//         $('.header .pc-nav ul li').removeClass('hover');
//         $('.header .pc-nav ul li.active').removeClass('not-active');
//     }
// });