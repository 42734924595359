module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<section class="ny-banner">\r\n    <div class="banner-left" style="background-color: #085b81;background-image: url(' +
((__t = (require('./images/pro_banner1_icon.jpg'))) == null ? '' : __t) +
');">\r\n        <div>自信源于品质<br>实力赢取信任</div>\r\n    </div>\r\n    <div class="banner-right" style="background-image: url(' +
((__t = (require('./images/pro_banner1.jpg'))) == null ? '' : __t) +
');"></div>\r\n</section>\r\n<section class="ny-menu">\r\n    <div class="menu-left" style="background-color: #15688e;">产品中心</div>\r\n    <div class="menu-right">\r\n        <a href="#" class="active">中萃天然</a>\r\n        <a href="#">吉宝生物</a>\r\n        <a href="#">吉宝中新</a>\r\n        <a href="#">景晨餐饮</a>\r\n        <a href="#">味香轩</a>\r\n    </div>\r\n</section>\r\n<section class="breadcrumb">\r\n    <div class="container container-1000">\r\n        <a href="/">首页</a><span></span>\r\n        <a href="#">产品中心</a><span></span>\r\n        <a href="#">中萃天然</a>\r\n    </div>\r\n</section>';

}
return __p
}