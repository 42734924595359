module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!title) var title = ''
;
__p += '\n<h4 class="csubtitle">\n  ' +
((__t = ( title )) == null ? '' : __t) +
'\n</h4>\n';

}
return __p
}