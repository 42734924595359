module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<footer>\r\n    <div class="footer">\r\n        <div class="footer-cont">\r\n            <div class="footer-left">\r\n                <a href="#">中萃天然</a>\r\n                <a href="#">吉宝生物</a>\r\n                <a href="#">吉宝中新</a>\r\n                <a href="#">景晨餐饮</a>\r\n                <a href="#">味香轩</a>\r\n            </div>\r\n            <div class="footer-right">\r\n                <div class="item-text">\r\n                    <h1>客服电话</h1>\r\n                    <h2><a href="tel:+0532-89225588">0532-89225588</a></h2>\r\n                    <p>联系我们</p>\r\n                </div>\r\n                <div class="item-img">\r\n                    <img src="' +
((__t = (require('./images/ewm.png'))) == null ? '' : __t) +
'" alt="">\r\n                </div>\r\n            </div>\r\n        </div>\r\n        <div class="copyright">\r\n            <div>©2021 吉宝（青岛）生物科技有限公司 版权所有</div>\r\n            <div>\r\n                <a href="#" target="_blank">鲁ICP备17004789号-1</a>\r\n                <a href="#" target="_blank">鲁公网安备 37021402000691号</a>\r\n                <a href="javascript:void(0);" data-open="map"><span>|</span>网站地图</a>\r\n            </div>\r\n        </div>\r\n        <div class="animate-circle">\r\n            <svg><rect></rect></svg>\r\n        </div>\r\n    </div>\r\n</footer>';

}
return __p
}