module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<header>\r\n    <div class="header">\r\n        <div class="header-logo">\r\n            <a href="/"><img src="' +
((__t = (require('./images/logo.png'))) == null ? '' : __t) +
'" alt=""></a>\r\n        </div>\r\n        <div class="header-nav">\r\n            <ul>\r\n                <li class="active"><a href="/"><span>首页</span></a></li>\r\n                <li><a href="#"><span>产品中心</span></a></li>\r\n                <li><a href="#"><span>关于我们</span></a></li>\r\n                <li><a href="#"><span>资讯动态</span></a></li>\r\n                <li><a href="#"><span>联系我们</span></a></li>\r\n            </ul>\r\n        </div>\r\n        <div class="header-right">\r\n            <div class="language"><a href="#">EN</a></div>\r\n            <div class="hamburger hamburger-11" data-open="cmenu">\r\n                <div></div>\r\n            </div>\r\n        </div>\r\n    </div>\r\n    <div class="menu-mask"></div>\r\n    <div class="menu-nav">\r\n        <ul>\r\n            <li><a href="/">首页</a></li>\r\n            <li><a href="#">产品中心</a></li>\r\n            <li><a href="#">关于我们</a></li>\r\n            <li><a href="#">资讯动态</a></li>\r\n            <li><a href="#">联系我们</a></li>\r\n        </ul>\r\n    </div>\r\n</header>';

}
return __p
}