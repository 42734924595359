module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!iconPath) var iconPath = '';
if (!text) var text = ''
;
__p += '\n\n<div class="ccard">\n  <img class="ccard-icon" src="' +
((__t = ( iconPath )) == null ? '' : __t) +
'" alt="">\n  <p class="ccard-text">' +
((__t = ( text )) == null ? '' : __t) +
'</p>\n</div>\n';

}
return __p
}