module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!textArr) var textArr = [];
;
__p += '\n<div class="cpanel">\n  ';
 textArr.forEach(function (item) { ;
__p += '\n    <p class="cpanel-item">\n      ' +
((__t = ( item )) == null ? '' : __t) +
'\n    </p>\n  ';
 }) ;
__p += '\n</div>\n';

}
return __p
}