module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!title) var title = ''
if (!desc) var desc = ''
;
__p += '\n<div class="cintro">\n  <div class="cintro-body">\n    ';
 if (title) { ;
__p += '\n      <h3 class="cintro-title">\n        ' +
((__t = ( title )) == null ? '' : __t) +
'\n      </h3>\n    ';
 } ;
__p += '\n    ';
 if (desc) { ;
__p += '\n      <p class="cintro-desc">\n        ' +
((__t = ( desc )) == null ? '' : __t) +
'\n      </p>\n    ';
 } ;
__p += '\n  </div>\n</div>\n';

}
return __p
}