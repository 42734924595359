module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!dataArr) var dataArr = [];
;
__p += '\n<nav class="ctab">\n  ';
 for (var i = 0; i < dataArr.length; i++) { ;
__p += '\n    <a class="ctab-item ' +
((__t = ( i === 0 ? 'active' : '' )) == null ? '' : __t) +
'" href="#">\n      ' +
((__t = ( dataArr[ i ] )) == null ? '' : __t) +
'\n    </a>\n  ';
 } ;
__p += '\n</nav>\n';

}
return __p
}