module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!logoPath) var logoPath = '';
if (!desc) var desc = ''
;
__p += '\n\n<div class="cbrand">\n  <img class="cbrand-logo" src="' +
((__t = ( logoPath )) == null ? '' : __t) +
'" alt="">\n  <p class="cbrand-desc">\n    ' +
((__t = ( desc )) == null ? '' : __t) +
'\n  </p>\n</div>\n';

}
return __p
}